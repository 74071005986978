<template>
 <div class="box box-primary">
  <div class="box-header with-border">
    <i class="fa fa-envelope"></i>
    <h3 class="box-title text-right">Mailing Messages Configuration</h3>
  </div>
  <div class="box-body">
        <div class="box-content box-bg-color">
            <div class="row">
                <div class="col-md-5">
                    <div class="form-group"><label class="control-label text-left">Mailing Name</label><input iservice-id="Name" type="text" class="form-control"></div>
                </div>
                <div class="col-md-5">
                    <div class="form-group"><label class="control-label text-left">Description</label><input iservice-id="Description" type="text" class="form-control"></div>
                </div>
                <div class="col-2"><button iservice-id="Save" class="btn btn-primary mt-3">Save</button></div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="box box-info">
                        <div class="box-header with-border">
                            <h3 class="box-title">Setup Your Mailing</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label text-left">Who are you emailing? Select your mailing list.</label>
                                    <div class="s">
                                         <Multiselect
                                        v-model="value"
                                        placeholder="-Select list-"
                                        label="name"
                                        :searchable="true"
                                        :options="[
                                           { value: 'All Customer', name: 'All Customers',level:'1'},
                                                
                                        ]"
                                        >
                                        <template v-slot:option="{ option }">
                                        <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                        </template>
                                    </Multiselect>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label text-left">
                                        Limit recepients based on a criteria. 
                                        <div class="popover-main">
                                        <span class="popover__text"><i class="fa fa-question-circle text-info help-icon"></i></span><!--v-if-->
                                        </div>
                                    </label>
                                    <div class="">
                                        <label class="control-label text-left"><a target="_blank" href="/page/mailing-criteria.html">Create a new criteria</a></label>
                                        <div>
                                           <Multiselect
                                                v-model="value"
                                                placeholder="-Select Criteria-"
                                                label="name"
                                                :searchable="true"
                                                :options="[
                                                  { value: 'Campaign', name: 'Campaign',level:'1'}
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                  <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label text-left">Select Your Message</label>
                                    <div class="">
                                      <Multiselect
                                                v-model="value"
                                                placeholder="-Select Message-"
                                                label="name"
                                                :searchable="true"
                                                :options="[
                                                  { value: 'Finance message', name: 'Finanace Message',level:'1'}
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                  <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label text-left">Message Topic</label>
                                    <div class=""><input type="text" class="form-control" disabled="" value="Call Center Management"></div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label text-left">Who is sending this message? Select a mailbox for the sender.</label>
                                    <div class="">
                                        <Multiselect
                                                v-model="value"
                                                placeholder="-Select Sender-"
                                                label="name"
                                                :searchable="true"
                                                :options="[
                                                  { value: 'Jhon Doe', name: 'Jhon Doe',level:'1'}
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                  <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="row">
                            <div class="col-md-12 col-12"><button class="btn btn-primary pull-left">Save Setup</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box box-info overlay-wrapper">
                        <div class="box-header with-border">
                            <h3 class="box-title">Send Your Mailing</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                            <div class="col-md-12">
                                <p class="input-text"> You will have an opportunity to preview the number of recipients after clicking Send Mailing. </p>
                                <p class="input-text" style="display: none;"> Your mailing has been sent.</p>
                                <div><button class="btn btn-primary">Send Mailing</button></div>
                                <div style="display: none;">
                                    <p class="input-text">Click Confirm to send mailing to <span class="text-orange"> contacts</span> on list, or Cancel to abort.</p>
                                    <button class="btn btn-primary" type="button">Confirm Send</button><button class="btn btn-link" type="button">Cancel</button>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="overlay" style="display: none;"><i class="fa fa-refresh fa-spin"></i></div>
                    </div>
                </div>
                </div>

        </div>
  </div>
</div>
</template>

<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import Toggle from '@vueform/toggle'
import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
import Variables from '../components/Variables.vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Department Settings',
   components: {
    Variables,
    VariableAndStockResponses,
    Multiselect,
  },
  setup(){      
      
       return{};
  }

}
</script>

<template>
 <div class="box box-primary">
  <div class="box-header with-border">
    <i class="fa fa-envelope"></i>
    <h3 class="box-title text-right">New Mailing Message</h3>
  </div>
  <div class="box-body">
       <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Mailing Name</label><input iservice-id="Name" type="text" class="form-control has-error"><!--v-if-->
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group"><label class="control-label text-left">Description</label><input iservice-id="Description" type="text" class="form-control"></div>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-2"><button iservice-id="Create" class="btn btn-primary">Create</button><button iservice-id="Cancel" class="btn btn-link">Cancel</button></div>
                    <div class="col-10 admin-error">
                        <!--v-if-->
                    </div>
                </div>
            </div>
            </div>

  </div>
</div>
</template>

<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import Toggle from '@vueform/toggle'
import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
import Variables from '../components/Variables.vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Mailing',
   components: {
    Variables,
    VariableAndStockResponses,
    Multiselect,
  },
  setup(){      
      
       return{};
  }

}
</script>
